<template>
  <div class="signature">
    <van-nav-bar
      :title="'tandatangan'"
      @click-left="$router.go(-1)"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>

    <!-- 签名 -->
    <div class="container" ref="canvasBox">
      <canvas class="canvasId"/>
    </div>
    <div class="btnBox">
      <van-button color="#212A2a" type="info" @click="open">Nota</van-button>
      <!-- <van-button :disabled="isAutograph" color="#212A2a" type="info" @click="clear">ตั้งค่าใหม่</van-button> -->
      <van-button :disabled="isAutograph" color="#212A2a" type="info" @click="save">{{$t('SUBMIT')}}</van-button>
    </div>
    <!-- end -->
    <div class="btnbox2">
      <van-button :disabled="!isAutograph" color="#212A2a" type="info" @click="$router.push({ path: '/hetong' })">Lihat kontrak pinjaman</van-button>
    </div>
  </div>
</template>

<script>
import SignaturePad from 'signature_pad'
import './css/my.css'
import { Dialog, Toast } from 'vant'
import { uploadPhoto } from '@/api/wallet'
import { autograph, getAutograph, myInformation } from '@/api/my'
export default {
  name: 'signature',
  data () {
    return {
      SignaturePad: null,
      // 签名数据
      signImg: '',
      //  画布配置
      config: {
        penColor: '#000000',
        minWidth: 3
      },
      isAutograph: false
    }
  },
  created () {
    // 获取签名是否填写
    this.init_myInformation()
    // 获取签名
    this.getSign()
  },
  mounted () {
    this.getCanvas()
  },
  methods: {
    open () {
      const _this = this
      Dialog.alert({
        title: 'Nota',
        message: `Nota ; 
Menandatangani adalah pengesahan maklumat yang dimasukkan ke dalam sistem dan pengesahan penyerahan maklumat pertimbangan kredit. Kontrak akan berkuat kuasa serta-merta selepas pinjaman diluluskan. Jika peminjam melanggar kontrak atas sebab peribadi Peminjam menerima akibat kontrak dan undang-undang sahaja.`,
        confirmButtonText: _this.$t('CONFIRM')
      }).then(() => {
        // on close
      })
    },
    // 获取签名是否填写
    async init_myInformation () {
      const { data } = await myInformation()
      this.isAutograph = data.data.autograph
    },
    // 获取画布
    getCanvas () {
      var canvas = document.querySelector('.canvasId')
      this.signaturePad = new SignaturePad(canvas, this.config)
      canvas.height = 200
      canvas.width = this.$refs.canvasBox.clientWidth - 20
    },
    // 签名功能
    async save () {
      const _this = this
      if (this.signaturePad.isEmpty()) {
        return Toast(this.$t('NOTNULL'))
      } else {
        const file = this.dataURLtoFile(this.signaturePad.toDataURL(), 'qianming.png')
        const formFile = new FormData()
        formFile.append('picture', file)
        const { data } = await uploadPhoto(formFile)
        if (data.code === 200) {
          const result = await autograph({
            autograph: `http://file.${this.getMainHost()}/${data.data}`
          })
          if (result.data.code === 200) {
            Toast({
              message: this.$t('SUBMITSUCCEED'),
              onClose: function () {
                if (_this.$route.query.id) {
                  _this.$router.push({
                    name: 'borrowdetail',
                    params: {
                      id: _this.$route.query.id
                    }
                  })
                } else {
                  // 返回上一层
                  _this.$router.go(-1)
                }
              }
            })
          } else {
            Toast(result.data.message)
          }
        } else {
          Toast(data.message)
        }
      }
    },
    // 清除
    clear () {
      // 清除画布内容
      this.signaturePad.clear()
    },
    // base64转成文件对象
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    getMainHost () {
      const key = `mh_${Math.random()}`
      const keyR = new RegExp(`(^|;)\\s*${key}=12345`)
      const expiredTime = new Date(0)
      const domain = document.domain
      const domainList = domain.split('.')

      const urlItems = []
      // 主域名一定会有两部分组成
      urlItems.unshift(domainList.pop())
      // 慢慢从后往前测试
      while (domainList.length) {
        urlItems.unshift(domainList.pop())
        const mainHost = urlItems.join('.')
        const cookie = `${key}=${12345};domain=.${mainHost}`

        document.cookie = cookie

        // 如果cookie存在，则说明域名合法
        if (keyR.test(document.cookie)) {
          document.cookie = `${cookie};expires=${expiredTime}`
          return mainHost
        }
      }
    },
    // 获取签名
    async getSign () {
      const { data } = await getAutograph()
      this.signaturePad.fromDataURL(data.data)
    }
  }
}
</script>

<style scoped>
.signature {
  font-size: 16px;
}
.container {
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid rgb(128, 128, 128);
}
.btnBox {
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
}
.btnbox2 {
  margin-top: 20px;
  padding-left: 20px;
}
</style>
